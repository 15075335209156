<nav class="navbar has-shadow" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="https://hituptheband.com">
      <div class="band-name">
        <span class="first-word">The</span><span class="second-word">Slamm</span>
      </div>
    </a>

    <a role="button" (click)="handleHamburger('#navbar')" class="navbar-burger ml-0" aria-label="menu" aria-expanded="false" data-target="navbar">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbar" class="navbar-menu">
    <div class="navbar-start">
      <a [routerLink]="['']" class="navbar-item pl-0">
        <span class="navbar-item icon-text">
          <span class="icon">
            <i class="fa fa-home"></i>
          </span>
          <span>Home</span>
        </span>
      </a>

      <a [routerLink]="['/schedule']" class="navbar-item">
        <span class="icon-text">
          <span class="icon">
            <i class="fa fa-calendar"></i>
          </span>
          <span>Schedule</span>
        </span>
      </a>

      <a [routerLink]="['/weddings']" class="navbar-item">
        <span class="icon-text">
          <span class="icon">
            <i class="fa fa-bell"></i>
          </span>
          <span>Weddings</span>
        </span>
      </a>

      <a [routerLink]="['/videos']" class="navbar-item">
        <span class="icon-text">
          <span class="icon">
            <i class="fa fa-video"></i>
          </span>
          <span>Videos</span>
        </span>
      </a>

      <a [routerLink]="['/about']" class="navbar-item">
        <span class="icon-text">
          <span class="icon">
            <i class="fa fa-question"></i>
          </span>
          <span>About</span>
        </span>
      </a>

    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">

        </div>
      </div>
    </div>

    <div class="social-media">
      <a class="button" href="https://www.facebook.com/theslamm2" target="_blank">
        <img src="../../assets/images/f_logo_RGB-Black_100.png" height="24" width="24">
      </a>
      <a class="button" href="https://www.instagram.com/theslamm" target="_blank">
        <img src="../../assets/images/glyph-logo_May2016.png" height="24" width="24">
      </a>
    </div>
  </div>
</nav>
