<app-header></app-header>

<div *ngIf="bannerMedia !== false" class="tile is-ancestor" style="padding:10px;">
  <div class="tile is-parent is-vertical is-12">
    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <a [href]="bannerMedia.youtubeLink"  target="_blank" class="image featured">
          <img [src]="bannerMedia.thumbnailFilename" alt="" />
        </a>
      </div>
    </div>
  </div>
</div>

<div class="tile is-ancestor" style="padding:10px;">
  <div *ngFor="let media of nonBannerMedia" class="tile is-parent is-vertical is-4">
    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <a [href]="media.youtubeLink"  target="_blank" class="image featured">
          <img [src]="media.thumbnailFilename" alt="" />
        </a>
      </div>
    </div>
  </div>
<!--
  <div class="tile is-parent is-vertical is-4">
    <div class="tile is-child box">
      <div class="content">
        <a href="../../../assets/images/the_slamm_wedding.mp4"  target="_blank" class="image featured">
          <img src="../../../assets/images/slammwedpic01.jpg" alt="" />
        </a>
      </div>
    </div>
  </div>
  <div class="tile is-vertical is-parent actions-container">
    <div class="tile is-child box">
      <div class="content">
        <a href="../../../assets/images/theslamm_wedding_clip.mp4"  target="_blank" class="image featured">
          <img src="../../../assets/images/slammwedpic02.jpg" alt="" />
        </a>
      </div>
    </div>
  </div> -->

</div>

<div class="tile is-ancestor" style="padding:10px;">
  <div class="tile is-parent">
    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <h3>Review</h3>
        <p class="client-review">&quot;Highly recommend! The Band was Fantastic! They were there with plenty of time to setup and get ready. The temp was crazy hot in the gym...but they were awesome to deal with. They played for our high school Senior "Afterprom" party. Kids loved it. Super job...would def hire again!! Thank you!!&quot;</p>
        <p class="name">~Annette D.</p>
        <h3>Client Ratings</h3>
        <ul>
          <li>Profressionalism: <span class="heavy">5</span></li>
          <li>Accommodating: <span class="heavy">5</span></li>
          <li>Overall Talent: <span class="heavy">5</span></li>
          <li>Recommendation: <span class="heavy">5</span></li>
          <li>Overall Satisfaction: <span class="heavy">5</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="tile is-ancestor" style="padding:10px;">
  <div class="tile is-parent">
    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <h3>Review</h3>
        <p class="client-review">&quot;Wow! What an awesome experience we had with this band! They arrived early to set up. All the band members were super friendly and courteous to our family and friends (young and older). Their playlist seem to build up the night just right. Truly I was surprised by their musical talent. We couldn't have asked for a better band to entertain our guest. All left raving about what a great time was had!&quot;</p>
        <p class="name">~Geri K.</p>
        <h3>Client Ratings</h3>
        <ul>
          <li>Profressionalism: <span class="heavy">5</span></li>
          <li>Accommodating: <span class="heavy">5</span></li>
          <li>Overall Talent: <span class="heavy">5</span></li>
          <li>Recommendation: <span class="heavy">5</span></li>
          <li>Overall Satisfaction: <span class="heavy">5</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="tile is-ancestor" style="padding:10px;">
  <div class="tile is-parent">
    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <h3>Review</h3>
        <p class="client-review">&quot;Really high energy band! They played songs that everyone knew! They were polite and accommodating! Loved having them for my event!&quot;</p>
        <p class="name">~Katie W.</p>
      </div>
    </div>
  </div>
</div>

<div class="tile is-ancestor" style="padding:10px;">
  <div class="tile is-parent">
    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <h3>Review</h3>
        <p class="client-review">&quot;They were amazing! Looking forward to having them back for a spring concert or even for a faculty party in December!&quot;</p>
        <p class="name">~Rod G.</p>
      </div>
    </div>
  </div>
</div>
