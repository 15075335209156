<section id="header">
  <div class="container">

    <!-- Nav -->
    <nav id="nav">
      <ul>
        <li><a class="icon solid fa-home" href="/"><span>Home</span></a></li>
        <li><a class="icon solid fa-calendar" href="schedule"><span>Schedule</span></a></li>

        <li><a class="icon solid fa-bell" href="weddings"><span>Weddings</span></a></li>

        <li><a class="icon solid fa-sitemap" href="videos"><span>Videos</span></a></li>

        <li><a class="icon solid fa-cog" href="about"><span>About</span></a></li>

      </ul>
    </nav>
  </div>
</section>
