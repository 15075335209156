import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-actions',
  templateUrl: './site-actions.component.html',
  styleUrls: ['./site-actions.component.scss']
})
export class SiteActionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
