import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid/dist';
import * as d3 from '@node_modules/d3/dist/d3';
import { ModalService } from '../services/modal.service';
import * as constants from '../../app/constants/constants';

export interface Song {
  type: string;
  name: string;
  artist?: string;
  color?: string;
  tags?: string;
  key?: string;
  time_signature?: string;
  tempo?: string;
  duration?: string;
  rating_average?: string;
  added?: string;
  updated?: string;
  last_event_date?: string;
  events_count?: string;
  ID?: string;
}

@Component({
  selector: 'app-song-list',
  templateUrl: './song-list.component.html',
  styleUrls: ['./song-list.component.scss']
})
export class SongListComponent implements OnInit {

  public songList: any = [];
  private SONG_LIST_URL: string =  constants.BAND_CONFIG.song_list_url;
  private MAIL_TO_EMAIL_ADDRESS: string = constants.BAND_CONFIG.band_email;

  constructor(private ModalService: ModalService) {
    this.ModalService.getJson(this.SONG_LIST_URL)
      .then(json => {
        // filter
        json = json.filter((item) => {
          return item.type === 'song';
        });
        this.songList = ModalService.addIds(json);
      });
  }

  ngOnInit(): void {
    this.ModalService.attachHandlers();
  }

  public getSelectedChoices(): void {
    let self = this;
    let atLeastOneSelected = false;
    let body = 'Songs Selected:\n\n';
    let checkboxes = d3.selectAll('input.song-checkbox');

    // make sure there's at least one song to pick from
    if(checkboxes['_groups'].length > 0) {
      checkboxes.each(function(d, idx) {
        let currentCheckbox = d3.select(this);
        let isChecked = currentCheckbox.property('checked');

        // if the song is selected
        if(isChecked) {
          atLeastOneSelected = true;

          let id = currentCheckbox.property('id');

          // grab the song object from the json
          let fullObject = self.songList.filter((song) => {
            return song.ID === id;
          });

          // add the song name and artist to our body variable
          body += fullObject[0].name + ' by ' + fullObject[0].artist + '\n';
        }
      });

      if(atLeastOneSelected) {
        let end = body.length - 1;
        body = body.substring(0, end); // rip off the last comma space
        body = encodeURIComponent(body); //encode for use in URL
        let subject = encodeURIComponent("Please Play These Songs!");
        let url = 'mailto: ' + this.MAIL_TO_EMAIL_ADDRESS + '?cc=&subject=' + subject + '&body=' + body;

        window.location.href = url; // open in same window
        // window.open(url, '_blank');
        this.ModalService.closeAllModals();
      } else {
        let songWarningModal = document.getElementById('select-song-warning-modal');
        this.ModalService.openSelectSongWarningModal(songWarningModal);
      }
    }
  }



}
