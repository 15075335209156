export const APP_CONFIG = {
  api_host: 'https://hituptheband.com/api',
  app_id: '181b6ca5-4319-4c65-9b26-487241fbb564',
  app_pw: '659a5346c3abe52183f33fd9f27d46ee1f9b6e6ca06dc3f6e0ea8ee712412981'
}

export const BAND_CONFIG = {
  song_list_url: 'https://www.bandhelper.com/feed/set_list/KRP7kd',
  genre_list_url: 'https://www.bandhelper.com/feed/smart_list/W9i9Uu/439',
  band_email: 'theslammband@gmail.com',
  mediaPath: '../../../assets/images/',
  wedding_media: [{
    isBanner: false,
    youtubeLink: '../../../assets/images/the_slamm_wedding.mp4',
    thumbnailFilename: 'slammwedpic01.jpg'
  },
  {
    isBanner: false,
    youtubeLink: '../../../assets/images/theslamm_wedding_clip.mp4',
    thumbnailFilename: 'slammwedpic02.jpg'
  },
  {
    isBanner: true,
    youtubeLink: '../../../assets/images/theslamm_wedding_clip.mp4',
    thumbnailFilename: 'slammtux_png.png'
  }],
  reviews: [
    {
      quote: "Highly recommend! The Band was Fantastic! They were there with plenty of time to setup and get ready. The temp was crazy hot in the gym...but they were awesome to deal with. They played for our high school Senior &quot;Afterprom&quot; party. Kids loved it. Super job...would def hire again!! Thank you!!",
      reviewer: "Annette D.",
      ratingScale: "1 - 5, Worst - Best",
      ratingsExist: true,
      ratings: [{
        metric: "Professionalism",
        score: 5
      },
      {
        metric: "Accommodating",
        score: 5
      },
      {
        metric: "Overall Talent",
        score: 5
      },
      {
        metric: "Recommendation",
        score: 5
      },
      {
        metric: "Overall Satisfaction",
        score: 5
      }]
    },
    {
      quote: "Wow! What an awesome experience we had with this band! They arrived early to set up. All the band members were super friendly and courteous to our family and friends (young and older). Their playlist seem to build up the night just right. Truly I was surprised by their musical talent. We couldn't have asked for a better band to entertain our guest. All left raving about what a great time was had!",
      reviewer: "Geri K.",
      ratingScale: "1 - 5, Worst - Best",
      ratingsExist: true,
      ratings: [{
        metric: "Professionalism",
        score: 5
      },
      {
        metric: "Accommodating",
        score: 5
      },
      {
        metric: "Overall Talent",
        score: 5
      },
      {
        metric: "Recommendation",
        score: 5
      },
      {
        metric: "Overall Satisfaction",
        score: 5
      }]
    },
    {
      quote: "Really high energy band! They played songs that everyone knew! They were polite and accommodating! Loved having them for my event!",
      reviewer: "Katie W.",
      ratingScale: "1 - 5, Worst - Best",
      ratingsExist: false,
      ratings: []
    },
    {
      quote: "They were amazing! Looking forward to having them back for a spring concert or even for a faculty party in December!",
      reviewer: "Rod G.",
      ratingScale: "1 - 5, Worst - Best",
      ratingsExist: false,
      ratings: []
    }
  ]
}
