<app-header></app-header>

<div class="box">
  <div class="columns">
    <div class="field column">
      <label class="label">To</label>
      <div class="control">
        <input class="input" type="text" [id]="toId" placeholder="To">
      </div>
    </div>
  </div>

  <div class="columns">
    <div class="field column">
      <label class="label">From</label>
      <div class="control has-icons-left has-icons-right">
        <input class="input" type="email" [id]="fromId" placeholder="From" value="">
        <span class="icon is-small is-left">
          <i class="fas fa-envelope"></i>
        </span>
      </div>
    </div>
  </div>

  <div class="field">
    <label class="label">Message</label>
    <div class="control">
      <textarea class="textarea" [id]="messageId" placeholder="Enter your message"></textarea>
    </div>
  </div>

  <div class="field is-grouped">
    <div class="control">
      <button class="button is-link" (click)="sendShoutOut()">Submit</button>
    </div>
  </div>

</div>

<div class="modal" id="shout-out-submit-modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Status</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body" style="overflow: hidden;">
      <!-- Content ... -->
      <div class="modal-content is-flex is-flex-direction-row is-justify-content-center"></div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success">Close</button>
    </footer>
  </div>
</div>
