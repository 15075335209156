<app-header></app-header>

<div class="tile is-ancestor" style="padding:10px;">

  <div class="tile is-parent is-vertical is-12">

    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <p class="band-about-blurb">The Slamm is a high-energy, party-rock band from Philadelphia. Book us for your party or wedding event today.</p>
      </div>
    </div>

    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <div class="band-member-container">
          <!-- Logo -->
          <img src="../../../assets/images/JoeNew.png" alt="">
          <div class="ml-4">
            <span class="member-name">Joe</span>
            <div class="instrument">Vox</div>
            <div class="instrument">Guitar</div>
            <div class="instrument">Keys</div>
          </div>
        </div>
      </div>
    </div>

    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <div class="band-member-container">
          <!-- Logo -->
          <img src="../../../assets/images/MarkieNew.png" alt="">
          <div class="ml-4">
            <span class="member-name">Markie</span>
            <div class="instrument">Vox</div>
            <div class="instrument">Guitar</div>
          </div>
        </div>
      </div>
    </div>

    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <div class="band-member-container">
          <!-- Logo -->
          <img src="../../../assets/images/EdNew.png" alt="">
          <div class="ml-4">
            <span class="member-name">Ed</span>
            <div class="instrument">Vox</div>
            <div class="instrument">Guitar</div>
          </div>
        </div>
      </div>
    </div>

    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <div class="band-member-container">
          <!-- Logo -->
          <img src="../../../assets/images/MattNew.png" alt="">
          <div class="ml-4">
            <span class="member-name">Matt</span>
            <div class="instrument">Vox</div>
            <div class="instrument">Drums</div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<app-contact-form></app-contact-form>
