import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { RequestGenreComponent } from './pages/request-genre/request-genre.component';
import { RequestSongComponent } from './pages/request-song/request-song.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { ShoutOutComponent } from './pages/shout-out/shout-out.component';
import { VideosComponent } from './pages/videos/videos.component';
import { WeddingsComponent } from './pages/weddings/weddings.component';
import { ShowsComponent } from './shows/shows.component';


const routes: Routes = [
  { path: 'theslamm', component: HomeComponent },
  { path: 'request-genre', component: RequestGenreComponent },
  { path: 'request-song', component: RequestSongComponent },
  { path: 'weddings', component: WeddingsComponent },
  { path: 'videos', component: VideosComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'shout-out', component: ShoutOutComponent },
  { path: 'schedule', component: ScheduleComponent },

  { path: '',   redirectTo: '/theslamm', pathMatch: 'full' } // redirect to HomeComponent
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
