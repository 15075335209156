import { Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public handleHamburger(targetSelector: string): void {
    // let existingClassesOnTarget = d3.select(targetSelector).attr('class');
    d3.select(targetSelector).classed('is-active', d3.select(targetSelector).classed("is-active") ? false : true);
  }

}
