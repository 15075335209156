<div class="modal" id="request-song-modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Choose which songs you want us to play...</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
      <!-- Content ... -->
      <!-- <app-request-song></app-request-song> -->
      <div *ngFor="let song of songList" class="form-check">
        <label class="checkbox">
          <input type="checkbox" class="song-checkbox checkbox" [value]="song.ID" [id]="song.ID">
          <span class="song-name">{{song.name}}</span> by {{song.artist}}
        </label>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button send-btn is-success" (click)="getSelectedChoices()">Send</button>
      <button class="button cancel-btn">Cancel</button>
    </footer>
  </div>
</div>

<div class="modal" id="select-song-warning-modal">
  <div class="modal-background"></div>
  <div class="modal-content is-flex is-flex-direction-row is-justify-content-center">
    Please make at least one selection.
  </div>
  <button class="modal-close is-large" aria-label="close"></button>
</div>
