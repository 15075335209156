import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';

//layout
import { FlexLayoutModule } from '@angular/flex-layout';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderComponent } from './header/header.component';

//services
import { ModalService } from './services/modal.service';
import { SongListComponent } from './song-list/song-list.component';
import { HomeComponent } from './pages/home/home.component';
import { RequestGenreComponent } from './pages/request-genre/request-genre.component';
import { RequestSongComponent } from './pages/request-song/request-song.component';
import { WeddingsComponent } from './pages/weddings/weddings.component';
import { VideosComponent } from './pages/videos/videos.component';
import { AboutComponent } from './pages/about/about.component';
import { SiteNavComponent } from './site-nav/site-nav.component';
import { SiteActionsComponent } from './site-actions/site-actions.component';
import { FooterComponent } from './footer/footer.component';
import { ShowsComponent } from './shows/shows.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ShoutOutComponent } from './pages/shout-out/shout-out.component';
import { ActionsComponent } from './actions/actions.component';
import { LogoComponent } from './logo/logo.component';
import { GenreListComponent } from './genre-list/genre-list.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SongListComponent,
    HomeComponent,
    RequestGenreComponent,
    RequestSongComponent,
    WeddingsComponent,
    VideosComponent,
    AboutComponent,
    SiteNavComponent,
    SiteActionsComponent,
    FooterComponent,
    ShowsComponent,
    ContactFormComponent,
    ContactComponent,
    ShoutOutComponent,
    ActionsComponent,
    LogoComponent,
    GenreListComponent,
    ScheduleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule
  ],
  providers: [
    ModalService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
