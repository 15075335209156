<!-- <div class="modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Choose which songs you want us to play...</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body"> -->
      <!-- Content ... -->
      <div *ngFor="let song of songList" class="form-check">
        <label class="checkbox">
          <input type="checkbox" class="song-checkbox checkbox" [value]="song.ID" [id]="song.ID">
          <span class="song-name">{{song.name}}</span> by {{song.artist}}
        </label>
        <!-- <label class="custom-checkbox">
          <input type="checkbox" [value]="song.ID" [id]="song.ID" />
          <i class="fa fa-fw fa fa-square-o unchecked"></i>
          <i class="fa fa-fw fa fa-check-square-o checked"></i>
          <span class="song-name">{{song.name}}</span> by {{song.artist}}
        </label> -->
      </div>
    <!-- </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="getSelectedChoices()">Save changes</button>
      <button class="button">Cancel</button>
    </footer>
  </div>
</div> -->



<!-- <div class="header" id="top">
  <div>Choose which songs you want us to play...</div>
  <div class="instruct">(click "Send" at the <a href="#bottom">bottom</a>)</div>
</div>

<div *ngFor="let song of songList" class="form-check">
  <label class="custom-checkbox">
    <input type="checkbox" [value]="song.ID" [id]="song.ID" />
    <i class="fa fa-fw fa fa-square-o unchecked"></i>
    <i class="fa fa-fw fa fa-check-square-o checked"></i>
    <span class="song-name">{{song.name}}</span> by {{song.artist}}
  </label>
</div>

<div class="footer">
  <div class="">Click "Send" to let the band know!</div>
  <div class="instruct">(Back to <a href="#top">top</a>)</div>
</div> -->

<!-- <div id="bottom" class="btn-container">
  <button type="button" (click)="getSelectedChoices()" class="btn btn-secondary">Send</button>
</div>

<div class="position-fixed bottom-50 start-0 p-3" style="z-index: 11">
  <div id="errorToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="visibility: hidden;">
    <div class="toast-header primary" style="background-color: rgb(251,230,165);">
      <strong class="me-auto" style="color: #000;">Notice</strong>
      <button type="button" style="background-color: #fff;" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Please select at least one song.
    </div>
  </div>
</div> -->
