<div class="">
  <ul class="actions">
    <li><a [routerLink]="['text-band']" class="button icon solid fa-file">Text Band a message</a></li>
  </ul>
  <ul class="actions">
    <li><a [routerLink]="['request-genre']" class="button icon solid fa-file">Tell Us what you want Genre you want us to play</a>
    </li>
  </ul>
  <ul class="actions">
    <li><a [routerLink]="['request-song']" class="button icon solid fa-file">Tell Us what you want Songs you want us to play</a>
    </li>
  </ul>
</div>
