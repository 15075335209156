import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid/dist';
import { ApiService } from '../services/api.service';
import * as d3 from 'd3';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  public hostId: string;
  public nameId: string;
  public emailId: string;
  public subjectId: string;
  public phoneId: string;
  public messageId: string;



  constructor(private apiService: ApiService, private modalService: ModalService) {
    this.hostId = uuidv4();
    this.nameId = `contact-name-${this.hostId}`;
    this.emailId = `contact-email-${this.hostId}`;
    this.subjectId = `contact-subject-${this.hostId}`;
    this.phoneId = `contact-phone-${this.hostId}`;
    this.messageId = `contact-message-${this.hostId}`;
   }

  ngOnInit(): void {
    this.modalService.attachHandlers();
  }

  public handleContactSubmit(): void {
    // collect form fields
    let contactName = d3.select(`#${this.nameId}`).property('value') || "<blank>";
    let contactEmail = d3.select(`#${this.emailId}`).property('value') || "<blank>";
    let contactSubject = d3.select(`#${this.subjectId}`).property('value') || "<blank>";
    let contactPhone = d3.select(`#${this.phoneId}`).property('value') || "<blank>";
    let contactMessage = d3.select(`#${this.messageId}`).property('value') || "<blank>";

    // package them up
    let data = {
      name: contactName,
      email: contactEmail,
      subject: contactSubject,
      phone: contactPhone,
      message: contactMessage
    }

    // post them to the API so it can send an email
    this.apiService.makeApiCall('/contact', 'POST', data)
      .then(json => {
        let el = document.getElementById("contact-submit-modal");
        d3.select('#contact-submit-modal').select('.modal-content').html(json.message);
        this.modalService.openModal(el);
      });
  }

}
