<div class="modal" id="request-genre-modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Choose which genres you want us to play...</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
      <!-- Content ... -->
      <!-- <app-request-song></app-request-song> -->
      <div *ngFor="let genre of genreList" class="form-check">
        <label class="checkbox">
          <input type="checkbox" class="genre-checkbox checkbox" [value]="genre.ID" [id]="genre.ID">
          <span class="genre-name">{{genre.genre}}</span>
          (<span *ngFor="let artist of genre.artists" class="genre-artist">{{artist.artist}}</span>)
        </label>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button send-btn is-success" (click)="getSelectedGenreChoices()">Send</button>
      <button class="button cancel-btn" (click)="ModalService.handleCancel()">Cancel</button>
    </footer>
  </div>
</div>
