import { Component, OnInit } from '@angular/core';
import { ModalService } from '../services/modal.service';
import * as d3 from '@node_modules/d3/dist/d3';
import { v4 as uuidv4 } from 'uuid/dist';
import { thresholdFreedmanDiaconis } from 'd3';

@Component({
  selector: 'app-genre-list',
  templateUrl: './genre-list.component.html',
  styleUrls: ['./genre-list.component.scss']
})
export class GenreListComponent implements OnInit {
  public genreList: any = [];
  private GENRE_LIST_URL: string = 'https://www.bandhelper.com/feed/smart_list/W9i9Uu/439';

  constructor(public ModalService: ModalService) {
    this.ModalService.getJson(this.GENRE_LIST_URL)
      .then(json => {
        // filter only sets
        let genreList = json.filter((item) => {
          return item.type === 'set';
         });

        let uniqueGenres = [];
        genreList.forEach(genre => {
          if(uniqueGenres.indexOf(genre) === -1) {
            uniqueGenres.push(genre.name);
          }
        });

        let artistsByGenre = [];
        uniqueGenres.forEach(genre => {
          let artists = [];

          // find the artists whose "tags" equals the genre
          artists = json.filter(element => {
            return element.tags === genre;
          });

          if(artists.length > 0) {
            // create an object out of the pieces
            let genreObj = {
              genre: genre,
              artists: artists
            }

            // save the object in an array for the template
            artistsByGenre.push(genreObj);
          }
        });

        this.genreList = artistsByGenre;
        this.genreList = ModalService.addIds(this.genreList);
      });
  }

  ngOnInit(): void {
  }

  public getSelectedGenreChoices(): void {
    let self = this;
    let atLeastOneSelected = false;
    let body = 'Genres Selected:\n\n';
    let checkboxes = d3.selectAll('input.genre-checkbox');

    // make sure there's at least one genre to pick from
    if(checkboxes['_groups'].length > 0) {
      checkboxes.each(function(d, idx) {
        let currentCheckbox = d3.select(this);
        let isChecked = currentCheckbox.property('checked');

        // if the genre is selected
        if(isChecked) {
          atLeastOneSelected = true;

          let id = currentCheckbox.property('id');

          // grab the genre object from the json
          let fullObject = self.genreList.filter((genre) => {
            return genre.ID === id;
          });

          // add the genre name to the body
          body += fullObject[0].genre + '\n';
        }
      });

      if(atLeastOneSelected) {
        let end = body.length - 1;
        body = body.substring(0, end); // rip off the last comma space
        body = encodeURIComponent(body); //encode for use in URL
        let subject = encodeURIComponent("Please Play These Genres!");
        let url = 'mailto: theslammband@gmail.com?cc=&subject=' + subject + '&body=' + body;

        window.location.href = url; // open in same window
        this.ModalService.closeAllModals();
      } else {
        let songWarningModal = document.getElementById('select-song-warning-modal');
        this.ModalService.openSelectSongWarningModal(songWarningModal);
      }
    }
  }

}
