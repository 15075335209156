import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid/dist';
import * as d3 from '@node_modules/d3/dist/d3';

/**
 * THIS IS NOT BEING USED.  IT"S BAKED INTO THE HOME COMPONENT RIGHT NOW.
 */

@Component({
  selector: 'app-request-song',
  templateUrl: './request-song.component.html',
  styleUrls: ['./request-song.component.scss']
})
export class RequestSongComponent implements OnInit {

  public songList: any = [];

  constructor() {
    this.getSongJson()
      .then(json => {
        this.songList = json;
        // console.log('fetched json:', json);
        this.addIds();
      });
  }

  private async getSongJson() {
    let response = await fetch('https://www.bandhelper.com/feed/set_list/KRP7kd');
    let json = await response.json();

    return json;
  }

  /**
   * Function to add IDs to the data - I saw dupes once and never will it happen again.
   */
  private addIds(): void {
    for(let i = 0; i < this.songList.length; i++) {
      this.songList[i].ID = uuidv4();
    }
  }

  ngOnInit(): void {
  }

  public getSelectedChoices() {
    let self = this;
    let atLeastOneSelected = false;
    let body = 'Songs Selected:\n\n';
    let checkboxes = d3.selectAll('.custom-checkbox').selectAll('input');

    // make sure there's at least one song to pick from
    if(checkboxes['_groups'].length > 0) {
      checkboxes.each(function(d, idx) {
        let currentCheckbox = d3.select(this);
        let isChecked = currentCheckbox.property('checked');

        // if the song is selected
        if(isChecked) {
          atLeastOneSelected = true;

          let id = currentCheckbox.property('id');

          // grab the song object from the json
          let fullObject = self.songList.filter((song) => {
            return song.ID === id;
          });
          // add the song name and artist to our body variable
          body += fullObject[0].name + ' by ' + fullObject[0].artist + '\n';
        }
      });

      if(atLeastOneSelected) {
        let end = body.length - 1;
        body = body.substring(0, end); // rip off the last comma space
        body = encodeURIComponent(body); //encode for use in URL
        let subject = encodeURIComponent("Please Play These Songs!");
        let url = 'mailto: theslammband@gmail.com?cc=&subject=' + subject + '&body=' + body;

        window.location.href = url;
      } else {
        // let toastHtml = d3.select('.toast').node();
        // let toast = new Toast(toastHtml);
        // toast.show();
      }
    }
  }

}
