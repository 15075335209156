<!-- <div class="tile is-ancestor" style="padding-top: 10px;"> -->
  <!-- <div class="tile is-parent is-12"> -->
  <div class="tile is-parent">
    <div class="tile is-child box">
      <div class="content">
        <!-- Content -->
        <h3>Shows</h3>
        <div *ngFor="let event of schedule" class="event-date-container">
          <span class="bandhelper_date">{{event.date_display}}</span> @ <span class="bandhelper_name">{{event.name}}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
<!-- </div> -->
