import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid/dist';
import * as d3 from '@node_modules/d3/dist/d3';
import * as constants from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private token: string = null;

  private API_HOST: string = constants.APP_CONFIG.api_host;
  private APP_ID: string = constants.APP_CONFIG.app_id;
  private APP_PW: string = constants.APP_CONFIG.app_pw;
  // private API_HOST: string = 'http://localhost:8888/api';
  // private API_HOST: string = 'http://hituptheband.com/api';
  // private APP_ID: string = '181b6ca5-4319-4c65-9b26-487241fbb564';
  // private APP_PW: string = '659a5346c3abe52183f33fd9f27d46ee1f9b6e6ca06dc3f6e0ea8ee712412981';

  constructor() {
   }

  public async login() {
    let URL = this.API_HOST + '/login';

    let response = await fetch(URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        loginId: this.APP_ID,
        password: this.APP_PW
      })
    });

    // get the response as json
    let json = await response.json();

    // just grab the token to be used in subsequent requests
    this.token = json.Token;

    return this.token !== null && this.token !== '';
  }

  /**
   *
   * @param endpoint
   * @param method
   * @param data an object matching the route json schema. ie. { someText: 'test' }
   * @returns
   */
  public async makeApiCall(endpoint, method, data) {
    let URL = this.API_HOST + endpoint;

    if(this.token !== null) {
      data.token = this.token;
      let options = {
        method: method,
        headers: new Headers({
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(data)
      };

      let response = await fetch(URL, options);
      let json = await response.json();

      return json;
    } else {
      return this.login()
        .then((response) => {
          return this.makeApiCall(endpoint, method, data);
        })
    }
  }
}
