import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  angularIcon = 'angular';
  d3Icon = 'd3';
  typeScriptIcon = 'typescript';
  
  constructor() { }

  ngOnInit(): void {
  }

}
