<div class="tile is-child box is-flex is-flex-direction-column is-align-items-flex-center actions-container">
  <button class="button mb-4" (click)="handleRequestSongs()">
    <span>Pick Songs We Play</span>
  </button>
  <button class="button mb-4" (click)="handleRequestGenre()">
    <span>Pick Genre We Play</span>
  </button>
  <button class="button mb-4" (click)="handleShoutOut()">
    <span>Send an On-Stage Shout-Out</span>
  </button>
  <button class="button mb-4" (click)="handleEmailBandBooking()">
    <span>Email Band/Booking</span>
  </button>
</div>

<app-genre-list></app-genre-list>

<app-song-list></app-song-list>


