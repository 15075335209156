import { Component, OnInit } from '@angular/core';
import * as constants from '../../../app/constants/constants';

@Component({
  selector: 'app-weddings',
  templateUrl: './weddings.component.html',
  styleUrls: ['./weddings.component.scss']
})
export class WeddingsComponent implements OnInit {
  public weddingMedia: any[] = constants.BAND_CONFIG.wedding_media;
  public mediaPath: string = constants.BAND_CONFIG.mediaPath;
  public reviews: any[] = constants.BAND_CONFIG.reviews;
  public bannerMedia: any = null;
  public nonBannerMedia: any[] = [];

  constructor() {
    this.addMediaPath();
    this.setBannerImage();
  }

  private addMediaPath(): void {
    this.weddingMedia.forEach((media) => {
      media.thumbnailFilename = this.mediaPath + media.thumbnailFilename;
    });
  }

  private setBannerImage(): void {
    this.weddingMedia.forEach((media) => {
      if(media.isBanner === true) {
        this.bannerMedia = media;
        // break out of loop
        return false;
      } else {
        this.nonBannerMedia.push(media);
      }
    });
  }

  ngOnInit(): void {
  }

}
