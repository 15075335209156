import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  public videoList: any[];
  private PLAYLIST_URL = 'https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyDMvdQJkdrBRI96pycc5sFx3_36Uyc29b8&playlistId=PLKDhhxpgyuxLayVEdDhS4d37rcMve_47b&part=snippet,id&maxResults=20';
  public playlistVideos1: any[];
  public playlistVideos2: any[];
  public playlistVideos3: any[];

  constructor(private sanitizer: DomSanitizer) {
    let self = this;
    this.playlistVideos1 = [];
    this.playlistVideos2 = [];
    this.playlistVideos3 = [];

    this.getPlaylistVideoData()
      .then(json => {
        let ids = [];
        let playlistItems = json.items;
        for(let i = 0; i < playlistItems.length; i++) {
          let item = playlistItems[i].snippet;
          let videoId = item.resourceId.videoId;
          let unsafeVideoLink = 'https://www.youtube.com/embed/' + videoId;
          let safeVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeVideoLink);

          // I want 3 columns so create an array for each so that we can use with *ngFor nicely
          if(i % 3 === 0) {
            self.playlistVideos1.push(safeVideoLink);
          } else if(i % 3 === 1) {
            self.playlistVideos2.push(safeVideoLink);
          } else {
            self.playlistVideos3.push(safeVideoLink);
          }

          //console.log('videoId: ', videoId);
        }


        //return playlistVideos;


        //this.addIds();
      });
  }

  ngOnInit(): void {
  }

  private async getPlaylistVideoData() {
    let response = await fetch(this.PLAYLIST_URL);
    let json = await response.json();

    return json;
  }

}
