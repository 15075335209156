import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid/dist';

export interface ScheduleJson {
  date_start: string;
  date_end: string;
  time_start: string;
  time_end: string;
  date_display: string;
  name: string;
  address: string;
  custom_eXkg7D: string; // ?
  ID: string;
  category: string;
  cancelled: string;
}

@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.scss']
})
export class ShowsComponent implements OnInit {

  public schedule: ScheduleJson[];

  private SCHEDULE_URL: string = 'https://www.bandhelper.com/feed/calendar/439?range=6';

  constructor() {
    this.getScheduleJson()
      .then(json => {
        this.schedule = json;
        // console.log('fetched json:', json);
        this.addIds();
      });
  }

  private async getScheduleJson() {
    let response = await fetch(this.SCHEDULE_URL);
    let json = await response.json();

    return json;
  }

  /**
   * Function to add IDs to the data - I saw dupes once and never will it happen again.
   */
   private addIds(): void {
    for(let i = 0; i < this.schedule.length; i++) {
      this.schedule[i].ID = uuidv4();
    }
  }

  ngOnInit(): void {
  }

}
