import { Component, OnInit } from '@angular/core';
import { ApiService } from '@src/app/services/api.service';
import { ModalService } from '@src/app/services/modal.service';
import * as d3 from 'd3';
import { v4 as uuidv4 } from 'uuid/dist';

@Component({
  selector: 'app-shout-out',
  templateUrl: './shout-out.component.html',
  styleUrls: ['./shout-out.component.scss']
})
export class ShoutOutComponent implements OnInit {
  public hostId: string;
  public toId: string;
  public fromId: string;
  public messageId: string;

  constructor(private apiService: ApiService, private modalService: ModalService) {
    this.hostId = uuidv4();
    this.toId = `shout-out-to-${this.hostId}`;
    this.fromId = `shout-out-from-${this.hostId}`;
    this.messageId = `shout-out-message-${this.hostId}`;
   }

  ngOnInit(): void {
    this.modalService.attachHandlers();
  }

  public sendShoutOut(): void {
    // collect form fields
    let shoutOutTo = d3.select(`#${this.toId}`).property('value');
    let shoutOutFrom = d3.select(`#${this.fromId}`).property('value');
    let shoutOutMessage = d3.select(`#${this.messageId}`).property('value');

    // package them up
    let data = {
      to: shoutOutTo,
      from: shoutOutFrom,
      message: shoutOutMessage
    }

    // post them to the API so it can send an email
    this.apiService.makeApiCall('/shoutOut', 'POST', data)
      .then(json => {
        // console.log('returned json:', json);
        let el = document.getElementById("shout-out-submit-modal");
        d3.select('#shout-out-submit-modal').select('.modal-content').html(json.message);
        this.modalService.openModal(el);
      });
  }

}
