import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid/dist';
import * as d3 from '@node_modules/d3/dist/d3';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  constructor(private router: Router, private ModalService: ModalService) {}

  ngOnInit(): void {
  }

  public handleRequestSongs(): void {
    let el = document.getElementById("request-song-modal");
    this.ModalService.openModal(el);
  }

  public handleRequestGenre(): void {
    let el = document.getElementById("request-genre-modal");
    this.ModalService.openModal(el);
  }

  public handleShoutOut(): void {
    this.router.navigate(['/shout-out']);
  }

  public handleEmailBandBooking(): void {
    this.router.navigate(['/contact']);
  }








}
